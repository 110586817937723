@import url('https://fonts.googleapis.com/css2?family=Martel+Sans:wght@200;300;400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

html{
  width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  position: relative;
  font-family: 'Martel Sans', sans-serif;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.unclicked {
    &::after,
    &::before{
        content: "";
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        opacity: 0;
        transition: all .4s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &::after{
        width: 30px;
        height: 1px;
        background: white;
        transform: translateX(-4px);
        margin-top: 0.7px;
    }

    &::before{
        content: "";
        transform: rotate(-135deg) translateX(50%);
        width: 15px;
        height: 15px;
        background: transparent;
        border-left: 2px solid white;
        border-bottom: 2px solid white;
        margin-top: -1px;
    }

    &:hover{
        padding: 15px 80px 15px 35px;
    }

    &:hover::after{
        opacity: 1;
        right: 15px;
    }
    
    &:hover::before{
        opacity: 1;
        right: 15px;
    }
}

.clicked {
  animation: in .2s;
  cursor: unset !important;
  background-color: rgba(86, 86, 86, 0.675) !important;
}

@keyframes in {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}

#curve {
  fill: transparent;
}

